import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomTextField from "components/CustomInput/CustomInput";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import Context from "../../../utils/context";
import Datetime from "react-datetime";
// @material-ui/core components
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "components/CustomInput/CustomInput.js";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import { primaryColor } from "assets/jss/xrs.js";

import Select from "@material-ui/core/Select";
import fnmoment from "moment/min/moment-with-locales";
import CardContent from "@material-ui/core/CardContent";
import * as URLS from "../../../utils/XRSAPIs";
import BlueHeader from "../../SectionsPage/Sections/BlueHeaderSection.js";
import {
  apiActions,
  getSiteDetailsActions,
} from "store/redux/redux-actions/redux_actions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  button: {
    marginRight: theme.spacing(1),
    padding: 13,
  },
  hide: {
    display: "none",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 30,
  },
  whiteInfo: {
    color: "white",
    fontWeight: 400,
    paddingLeft: 0,
    paddingTop: 10,
  },
  whiteInfoMiddle: {
    color: "white",
    fontWeight: 400,
    paddingLeft: 0,

    textAlign: "center",
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: "#0065a4",
    fontWeight: 400,
  },
  blueText: {
    color: "#0065a4",
    fontWeight: 300,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
  },
  Wellcome: {
    fontWeight: 500,
  },
  BoxFull: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  Middle: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    border: 0,
  },
  blueTextMiddle: {
    color: "#0065a4",
    fontWeight: 400,
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    border: 0,
  },
  controlWhite: {
    margin: 0,
    height: 40,
    borderRadius: 2,
    backgroundColor: "#f5f9ff",
    color: "#0088cc",
    borderColor: "#0088cc",
    border: 1,
  },
  rightValues: {
    textAlign: "right",
    fontWeight: 300,
  },
}));

export default function RequestCallBackSection() {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const context = useContext(Context);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ApiCallsReducer.loading);

  const [sitename, setSitename] = React.useState("");
  const [companyname, setCompanyname] = React.useState("");
  const [aboutus, setAboutus] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [officehours, setOfficehours] = React.useState("");
  const [phonenumber, setPhonenumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [openTime, setopenTime] = React.useState("");
  const [closeTime, setcloseTime] = React.useState("");

  const [callbackReason, setCallbackReason] = React.useState("");
  const [callbackDate, setCallBackDate] = React.useState("");
  const [callbackDateMax, setCallBackDateMax] = React.useState("");
  const [callbackTime, setCallBackTime] = React.useState("");
  const [callbackphone, setCallbackphone] = React.useState("");
  const [callbackDateTime, setCallbackDateTime] = React.useState("None");
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);
  const stateCallbacks = useSelector(
    (state) => state.CallbacksReducer.callbacks
  );
  React.useEffect(() => {
    debugger;
    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Welcome Details", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        dispatch(getSiteDetailsActions.logo(response.logoimageurl));
        dispatch(getSiteDetailsActions.footerImage(response.footerimageurl));
        dispatch(getSiteDetailsActions.currency(response.sitecurrency));

        setSitename(response.sitename);
        setCompanyname(response.companyname);
        setAboutus(response.aboutus);
        debugger;
        setOfficehours(response.officehours);
        setPhonenumber(response.phonenumber);
        setAddress(response.address);
        setEmail(response.email);
        setcloseTime(response.closinghours);
        setopenTime(response.openinghours);
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
  }, []);
  const handlecallbackReasonChange = (e) => {
    setCallbackReason(e.target.value);
  };

  const handleCallBackDateChange = (e) => {
    var d = fnmoment(e.target.value);

    setCallBackDate(d.format("YYYY-MM-DD"));
    var date = d.format("L");
    var time = callbackTime;
    var datetime = date + " " + time;
    setCallbackDateTime(datetime);
  };
  const handleCallBackTimeChange = (e) => {
    setCallBackTime(e.target.value);

    var date = callbackDate;
    var time = e.target.value;
    var datetime = date + " " + time;
    setCallbackDateTime(datetime);
  };
  const handleCallBackPhoneChange = (e) => {
    setCallbackphone(e.target.value);
  };
  const token = sessionStorage.getItem("access_token");
  const submitForm = (event) => {
    event.preventDefault();
    if (document.forms[0].checkValidity()) {
      const xhr = new XMLHttpRequest();
      var url = URLS.SaveCallBack;

      dispatch(apiActions.loading(true));
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", "Bearer " + token);

      var date = callbackDate;
      var time = callbackTime;
      var datetime = date + " " + time;
      var callBacklDate = fnmoment(datetime).format("YYYY-MM-DD HH:mm:ss ");
      var data = JSON.stringify({
        DateOfCallBack: callBacklDate,
        Note: callbackReason,
        PhoneNumber: callbackphone,
        PhoneType: "Home",
      });
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status == 200 || xhr.status == 201) {
            context.stateInfoDialog(
              "Callback Information",
              JSON.parse(xhr.responseText).message,
              "/welcome"
            );
            //setIfamevisible(true);
          } else {
            ApiErrorHandler("Save Call Back", xhr, context);
          }
        }
      };
      try {
        xhr.send(data);
      } catch (err) {
        ApiErrorHandler("Save Call Back", err, context);
      }
    }
  };
  React.useEffect(() => {
    fnmoment.locale(window.navigator.language.toLowerCase());
  }, []);
  React.useEffect(() => {
    setCallbackphone(stateCustomer.cumobilephone);
    setCallBackDate(fnmoment().format("YYYY-MM-DD"));
    setCallbackDateTime(fnmoment().format("L"));

    var today = fnmoment()
      .add(stateCustomer.maxcallbackdate, "days")
      .format("YYYY-MM-DD");
    setCallBackDateMax(today);
    return () => {
      dispatch(apiActions.clear());
    };
  }, []);

  return (
    <div>
      <BlueHeader></BlueHeader>
      <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
        <CardBody>
          <form className={classes.form} onSubmit={submitForm}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h5>Choose a call back date</h5>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      views={["year", "month", "date"]}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      variant="outlined"
                      inputProps={{
                        type: "date",
                        required: "required",
                        min: fnmoment().format("YYYY-MM-DD"),
                        value: callbackDate,
                        onChange: (e) => {
                          handleCallBackDateChange(e);
                        },
                        name: "callbackDate",
                        // min: fnmoment(fnmoment()).format("YYYY-MM-DD"),
                        max: callbackDateMax,
                      }}
                      className={classes.control}
                    ></TextField>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <h5>Choose a call back time</h5>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomTextField
                      id="callbackTime"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      required
                      inputProps={{
                        type: "time",
                        required: "required",
                        autoComplete: "off",
                        min: openTime,
                        max: closeTime,

                        onChange: (e) => {
                          handleCallBackTimeChange(e);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <h5>Choose call back reason</h5>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Select
                      className={classes.controlWhite}
                      onChange={(e) => {
                        handlecallbackReasonChange(e);
                      }}
                      fullWidth
                      required
                      inputProps={{
                        required: "required",
                      }}
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={callbackReason}
                    >
                      {stateCallbacks.map(function(res, index) {
                        return (
                          <MenuItem
                            key={res.callbackid}
                            value={res.callbackreason}
                          >
                            {res.callbackreason}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <h5 className={classes.whiteText}>Enter a phone number</h5>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomTextField
                      id="phone"
                      required
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "phone",
                        autoComplete: "off",
                        required: "required",
                        value: callbackphone,
                        onChange: (e) => {
                          handleCallBackPhoneChange(e);
                        },
                      }}
                    />{" "}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.textCenter}>
                      <Card className={classes.cardRadius}>
                        <CardContent className={classes.cardRadius}>
                          <p className={classes.centerText}>
                            Selected date and time
                          </p>
                          <p className={classes.centerTextBlue}>
                            {callbackDateTime}
                          </p>
                        </CardContent>
                      </Card>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.textCenter}>
                      <Button
                        disabled={loading}
                        type="submit"
                        color="primary"
                        fullWidth
                      >
                        Request Call
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <br></br>

                <h3>How it works</h3>

                <p>We are happy to give you a call whenever it suits you!</p>
                <p>We will try our best to call you at your requested time</p>
                <p>Using our handy form, follow these steps:</p>

                <ol>
                  <li>Select a date for the call.</li>
                  <li>Select a time for the call that suits you.</li>
                  <li>Enter phone number</li>
                  <li>Choose a reason for the call.</li>
                  <li>Finally, click Request Call.</li>
                </ol>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}
