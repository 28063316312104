import { Collapse } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import CustomTextField from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import { useFormik } from "formik";
import React, { useContext } from "react";
import * as ACTIONS from "../../store/actions/actions";
import { ApiErrorHandler } from "utils/apiErrorHandler";

import Context from "../../utils/context";
import history from "../../utils/history";
import * as URLS from "../../utils/XRSAPIs";
import FooterSection from "../../views/SectionsPage/Sections/FooterSection.js";
import HeaderSection from "../../views/SectionsPage/Sections/HeaderSection.js";
import LinkSection from "../../views/SectionsPage/Sections/LinksSection.js";
import {
  apiActions,
  getSiteDetailsActions,
  htmlActions,
} from "../../store/redux/redux-actions/redux_actions";
import { useDispatch, useSelector } from "react-redux";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";

const useStyles = makeStyles(landingPageStyle);

export default function LandingPageCreateUser(props) {
  const dispatch = useDispatch();

  const context = useContext(Context);
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [sitename, setSitename] = React.useState("");
  const [companyname, setCompanyname] = React.useState("");
  const [aboutus, setAboutus] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [officehours, setOfficehours] = React.useState("");
  const [phonenumber, setPhonenumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [registrationType, setRegistrationType] = React.useState("both");
  const [
    selectedregistrationType,
    setselectedRegistrationType,
  ] = React.useState(null);

  const [firstDigitName, setfirstDigitName] = React.useState("");
  const [secondDigitName, setsecondDigitName] = React.useState("");
  const [thirdDigitName, setthirdDigitName] = React.useState("");
  const [firstDigitValue, setfirstDigitValue] = React.useState("");
  const [secondDigitValue, setsecondDigitValue] = React.useState("");
  const [thirdDigitValue, setthirdDigitValue] = React.useState("");

  const [NotFound, setNotFound] = React.useState(false);
  const [registerValues, setRegisterValues] = React.useState("");
  const [resetValues, setResetValues] = React.useState("");
  const [retruevedUser, setretruevedUser] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");
  const [mainscreen, setMainScreen] = React.useState(false);
  const [verifyPin, setVerifyPin] = React.useState(false);
  const [registerscreen, setRegisterScreen] = React.useState(true);
  const [resetPinscreen, setResetPinscreen] = React.useState(false);
  const [changePinscreen, setChangePinscreen] = React.useState(false);
  const [paymentResult, setPaymentResult] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertText, setAlertText] = React.useState("None");

  const loading = useSelector((state) => state.ApiCallsReducer.loading);

  const [customerCaption, setcustomerCaption] = React.useState("");

  const ResetPins = () => {
    LoginForm.values.firstValue = "";
    LoginForm.values.secondValue = "";
    LoginForm.values.thirdValue = "";
  };
  const focusUsername = document.querySelector(`input[id=username]`);
  const focusPass = document.querySelector(`input[id=pass]`);

  const focusFirst = document.querySelector(`input[id=firstValue]`);
  const focusSecond = document.querySelector(`input[id=secondValue]`);
  const focusThird = document.querySelector(`input[id=thirdValue]`);
  const focusButton = document.querySelector(`button[id=signIn]`);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    let search = window.location.search;
    dispatch(ACTIONS.set_Customer("NoCoutomer"));
    dispatch(ACTIONS.set_Accounts("NoAccount"));
    dispatch(ACTIONS.set_selectedAccount("NoAccount"));
    context.dispatchDrawerTwo("NoDrawer");
    context.dispatchDrawerThree("NoDrawer");
    context.dispatchDrawerOne("NoDrawer");
    context.dispatchDrawer("NoDrawer");

    if (search.indexOf("Success") !== -1) {
      setPaymentResult("Success");
      setAlertText("Success");
      setAlertOpen(true);
    }
    if (search.indexOf("Failure") !== -1) {
      setPaymentResult("Failure");
      setAlertText("Failure");
      setAlertOpen(true);
    }
  }, []);

  const makeFormEncoder = (hash) => {
    return function(key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(hash[key]);
    };
  };

  React.useEffect(() => {
    dispatch(ACTIONS.set_Customer("NoCoutomer"));

    dispatch(ACTIONS.set_Accounts("NoAccount"));
    dispatch(ACTIONS.set_selectedAccount("NoAccount"));

    if (props.create == "true") {
      setMainScreen(false);
      setRegisterScreen(true);
      setVerifyPin(false);
    }
    getPINDigits();
    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Site Detatils", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        //Set site details
        dispatch(
          getSiteDetailsActions.paymentProvider(response.paymentprovider)
        );
        dispatch(getSiteDetailsActions.logo(response.logoimageurl));
        dispatch(getSiteDetailsActions.footerImage(response.footerimageurl));
        dispatch(getSiteDetailsActions.currency(response.sitecurrency));

        let type = response.registrationtype
          ? response.registrationtype.toLowerCase()
          : null;
        setselectedRegistrationType(type == "both" ? "personal" : type);
        setRegistrationType(type);
        setSitename(response.sitename);
        setCompanyname(response.companyname);
        setAboutus(response.aboutus);
        setOfficehours(response.officehours);
        setPhonenumber(response.phonenumber);
        setAddress(response.address);
        setEmail(response.email);
        setcustomerCaption(response.custcaption);
        dispatch(ACTIONS.set_Site(response));
        context.dispatchIntercomKey(
          response.chatauth,
          response.chatvisible == "Y" ? true : false
        );
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
  }, [page]);

  const handleClose = () => {
    setAlertOpen(false);
  };
  const changeRegisterType = (e, registrationTypeprop) => {
    setselectedRegistrationType(registrationTypeprop);
  };
  const registerClick = () => {
    setMainScreen(false);
    setResetPinscreen(false);
    setRegisterScreen(true);
    setVerifyPin(false);
    setNotFound(false);
  };

  const ResetPinAndPasswordClick = () => {
    setResetPinscreen(true);
    setMainScreen(false);
    setRegisterScreen(true);
    setVerifyPin(false);
    setNotFound(false);
  };
  const loginClick = () => {
    setMainScreen(true);
    setRegisterScreen(false);
    setResetPinscreen(false);
    setVerifyPin(false);
    setNotFound(false);
  };
  const submitRegisterForm = (values) => {
    var xhr = new XMLHttpRequest();
    var data;
    var url;
    if (selectedregistrationType == null) {
      url = URLS.GetcreateUserAPIUrl;
      data = JSON.stringify({
        UserName: values.regusername,
        Reference: values.customernumber,
        MobileNumber: values.phone,
        DateOfBirth: values.dob,
        Password: values.pass,
        ConfirmPassword: values.passconfirm,
      });
      if (resetPinscreen) {
        url = URLS.GetRecreateUserAPIUrl;
      }
    } else if (selectedregistrationType == "business") {
      data = JSON.stringify({
        UserName: values.regusername,
        Reference: values.customernumber,
        Email: values.email,
        Password: values.pass,
        ConfirmPassword: values.passconfirm,
      });
      url = URLS.GetCreateBusinesUserAPIUrl;
      if (resetPinscreen) {
        url = URLS.GetReCreateBusinesUserAPIUrl;
      }
    } else if (selectedregistrationType == "personal") {
      data = JSON.stringify({
        UserName: values.regusername,
        Reference: values.customernumber,
        EmailAddress: values.email,
        MobileNumber: values.phone,
        Password: values.pass,
        ConfirmPassword: values.passconfirm,
      });
      url = URLS.GetCreatePersonalUserAPIUrl;
      if (resetPinscreen) {
        url = URLS.GetReCreatePersonalUserAPIUrl;
      }
    }

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        dispatch(apiActions.loading(false));

        if (xhr.status == 200 || xhr.status == 201) {
          const json = JSON.parse(xhr.responseText);

          setretruevedUser(json);
          setMainScreen(false);
          setRegisterScreen(false);
          setVerifyPin(true);
          setNotFound(false);
          //   break;
          // case 404:
          //   var message = "User not found!";
          //   context.stateInfoDialog("Register", message);
          //   break;
          // case 400:
          //   var errors = JSON.parse(xhr.responseText).errors;
          //   var message = "";
          //   errors.forEach((element) => (message += element + "\n"));

          //   context.stateInfoDialog("Register", message);
          //   break;
          // case 500:
          //   var errors = JSON.parse(xhr.responseText).errors;
          //   var message = "";
          //   context.stateInfoDialog("Register", message);
          //   errors.forEach((element) => (message += element + "\n"));
          //   break;
          // default:
          //   break;
        } else {
          ApiErrorHandler("Create Account", xhr, context);
        }
      }
    };

    xhr.send(data);
  };
  const submitResetForm = (values) => {
    var xhr = new XMLHttpRequest();
    var url = URLS.GetReset;
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    var data;
    data = JSON.stringify({
      Reference: values.resetcustomernumber,
      MobileNumber: values.resetphone,
      DateOfBirth: values.resetdob,
      CredentialToReset: "Password",
    });
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status == 200 || xhr.status == 201) {
          const json = JSON.parse(xhr.responseText);

          setMainScreen(false);
          setRegisterScreen(false);
          setResetPinscreen(false);
          setVerifyPin(true);
          setNotFound(false);
          //   break;
          // case 404:
          //   setNotFound(true);
          //   setMainScreen(false);
          //   setRegisterScreen(false);
          //   setResetPinscreen(false);
          //   setVerifyPin(false);
          //   break;
          // case 400:
          //   alert(JSON.parse(xhr.responseText));
          //   break;
          // case 500:
          //   alert(JSON.parse(xhr.responseText));
          //   break;
          // default:
          //   break;
        } else {
          ApiErrorHandler("Reset", xhr, context);
        }
      }
    };

    xhr.send(data);
  };
  const displayDigits = (data) => {
    var first = data[0];
    setfirstDigitName(first.description);
    setfirstDigitValue(first.digit);
    var second = data[1];
    setsecondDigitName(second.description);
    setsecondDigitValue(second.digit);
    var third = data[2];
    setthirdDigitName(third.description);
    setthirdDigitValue(third.digit);
  };
  const getPINDigits = () => {
    var xhr = new XMLHttpRequest();
    var url = URLS.GetPINDigitsAPIUrl;
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status == 200 || xhr.status == 201) {
          const json = JSON.parse(xhr.responseText);

          if (json !== null) {
            displayDigits(json);
          } else {
            alert(json);
          }
          //   break;
          // case 404:
          //   context.stateInfoDialog("Retrieve pin digits", xhr.responseText);
          //   break;
          // default:
          //   context.stateInfoDialog("Retrieve pin digits", xhr.responseText);
          //   break;
        } else {
          ApiErrorHandler("Retrieve pin digits", xhr, context);
        }
      }
    };

    xhr.send();
  };
  const registerForm = useFormik({
    initialValues: {
      customernumber: "",
      email: "",
      regusername: "",
      phone: "",
      dob: "",
      pass: "",
      passconfirm: "",
    },
    onSubmit: (values) => {
      dispatch(apiActions.loading(true));

      if (values.pass != values.passconfirm) {
        context.stateInfoDialog(
          "Create Account",
          "Passwords and Confirm password do not match!"
        );
        return;
      }
      setRegisterValues(values);
      submitRegisterForm(values);
    },
  });
  const resetForm = useFormik({
    initialValues: {
      resetcustomernumber: "",
      resetphone: "",
      resetdob: "",
    },
    onSubmit: (values) => {
      setResetValues(values);
      submitResetForm(values);
    },
  });

  const getMenu = () => {
    var url = URLS.GetWelcomeNavigation;
    var token = sessionStorage.getItem("access_token");

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Welcome Navigation", data, context);
          });
          throw Error("");
        }
      })
      .then((data1) => {
        dispatch(ACTIONS.set_menu(data1));

        fetch(URLS.GetWelcomeDetails, {
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              return res.json();
            } else {
              ApiErrorHandler("Get Welcome Details", res, context);
              throw Error("");
            }
          })
          .then((data) => {
            dispatch(htmlActions.termsQuickPay(data.customer.termsquickpay));
            dispatch(htmlActions.termsPlan(data.customer.termsplan));
            dispatch(htmlActions.termsDiscount(data.customer.termsdiscount));

            dispatch(
              htmlActions.viewOfferText(data.activeAccounts[0].viewoffertext)
            );
            dispatch(
              htmlActions.createPlanText(data.activeAccounts[0].createplantext)
            );
            dispatch(
              htmlActions.createPlantoc(data.activeAccounts[0].createplantoc)
            );
            dispatch(
              htmlActions.createBudgetText(
                data.activeAccounts[0].createbudgettext
              )
            );
            dispatch(
              htmlActions.viewAccountText(
                data.activeAccounts[0].viewacccounttext
              )
            );
            dispatch(ACTIONS.set_Customer(data.customer));

            sessionStorage.setItem("user", data.customer.cufname);
            dispatch(
              ACTIONS.set_Registration_method(
                data.customer.registrationmethod == null
                  ? data.customer.registrationmethod
                  : data.customer.registrationmethod.toLowerCase()
              )
            );

            dispatch(ACTIONS.set_Accounts(data.activeAccounts));
            dispatch(ACTIONS.set_num_accounts(data.activeAccounts.length));

            dispatch(ACTIONS.set_Callbacks(data.callbackReasons));

            if (data.activeAccounts.length == 1) {
              dispatch(ACTIONS.set_selectedAccount(data.activeAccounts[0]));
            }
            dispatch(apiActions.clear());
            history.replace(data1.targetURL);
          })
          .catch((error) => {
            if (error.message == "") {
            } else {
              ApiErrorHandler("Get Welcome Details", error, context);
            }
          });
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Welcome Navigation", error, context);
        }
      });
  };

  const Logon = (values, firstPosition, secondPosition, thirdPosition) => {
    var xhr = new XMLHttpRequest();
    var url = URLS.GetTokenURLAPI;
    dispatch(apiActions.loading(true));
    var formValues = {
      grant_type: "password",
      username: values.username,
      password: values.password,
      validatePIN: "true",
      client_id: "PaymentPortal",
      firstValue: values.firstValue,
      firstPosition: firstPosition,
      secondValue: values.secondValue,
      secondPosition: secondPosition,
      thirdValue: values.thirdValue,
      thirdPosition: thirdPosition,
    };

    const pairs = Object.keys(formValues).map(makeFormEncoder(formValues));
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status == 200 || xhr.status == 201) {
          var json = JSON.parse(xhr.responseText);
          var access_token = json.access_token;
          var refresh_token = json.refresh_token;
          var expires_in_seconds = json.expires_in;
          var timeoutDate;
          timeoutDate = new Date();
          timeoutDate.setSeconds(timeoutDate.getSeconds() + expires_in_seconds);
          sessionStorage.setItem("user", values.username);
          sessionStorage.setItem("expiresAt", timeoutDate);
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);

          getMenu();

          //   break;
          // case 404:
          //   dispatch(
          //     apiActions.error(JSON.parse(xhr.responseText).error_description)
          //   );
          //   context.stateInfoDialog(
          //     "Login",
          //     JSON.parse(xhr.responseText).error_description
          //   );
          //   break;
          // case 400:
          //   dispatch(
          //     apiActions.error(JSON.parse(xhr.responseText).error_description)
          //   );
          //   context.stateInfoDialog(
          //     "Login",
          //     JSON.parse(xhr.responseText).error_description
          //   );
          //   break;
          // case 500:
          //   dispatch(
          //     apiActions.error(JSON.parse(xhr.responseText).error_description)
          //   );
          //   context.stateInfoDialog(
          //     "Login",
          //     JSON.parse(xhr.responseText).error_description
          //   );
          //   break;
          // default:
          //   break;
        } else {
          ApiErrorHandler("Sign in", xhr, context);
          let parsedError = JSON.parse(xhr.responseText);
          if (
            parsedError.error_description === "User did not enter correct PIN"
          ) {
            ResetPins();
          }
        }
      }
    };
    var postValues = pairs.join("&");
    xhr.send(postValues);
  };

  const getPinVerification = (values, record, pin) => {
    var xhr = new XMLHttpRequest();
    var url =
      selectedregistrationType == null || selectedregistrationType == "personal"
        ? URLS.GetVerifyAPIUrl
        : URLS.GetVerifyByEmailAPIUrl;
    let verifyData = {};
    if (selectedregistrationType == "business") {
      verifyData.id = record.id;
      verifyData.userName = values.regusername;
      verifyData.emailComfirmed = record.emailConfirmed;
      verifyData.emailAddress = record.emailAddress;
      verifyData.reference = values.customernumber;
      verifyData.customerRecordNo = record.customerRecordNo;
      verifyData.pin = pin;
    } else {
      verifyData.id = record.id;
      verifyData.userName = values.regusername;
      verifyData.phoneConfirmed = record.phoneConfirmed;
      verifyData.mobileNumber = values.phone;

      verifyData.dateOfBirth = values.dob;

      verifyData.reference = values.customernumber;
      verifyData.customerRecordNo = record.customerRecordNo;
      verifyData.pin = pin;
    }
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status == 200 || xhr.status == 201) {
          setMainScreen(true);
          setRegisterScreen(false);
          setVerifyPin(false);

          var xhrtoken = new XMLHttpRequest();
          var urltoken = URLS.GetTokenURLAPI;

          var valuesPost = {
            grant_type: "password",
            username: values.regusername,
            password: values.pass,
            validatePIN: "false",
            client_id: "PaymentPortal",
          };
          // eslint-disable-next-line no-case-declarations
          const pairs = Object.entries(valuesPost)
            .map(
              ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");

          xhrtoken.open("POST", urltoken, true);
          xhrtoken.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded"
          );
          xhrtoken.onreadystatechange = function() {
            if (xhrtoken.readyState === 4) {
              if (xhr.status == 200 || xhr.status == 201) {
                var json = JSON.parse(xhrtoken.responseText);
                var access_token = json.access_token;
                var refresh_token = json.refresh_token;
                var expires_in_seconds = json.expires_in;
                var timeoutDate;
                timeoutDate = new Date();
                timeoutDate.setSeconds(
                  timeoutDate.getSeconds() + expires_in_seconds
                );
                sessionStorage.setItem("expiresAt", timeoutDate);
                sessionStorage.setItem("access_token", access_token);
                sessionStorage.setItem("refresh_token", refresh_token);

                getMenu();
                //   break;
                // case 404:
                //   context.stateInfoDialog(
                //     "Login",
                //     JSON.parse(xhrtoken.responseText).error_description
                //   );
                //   break;
                // default:
                //   context.stateInfoDialog("Login", xhrtoken.responseText);

                //   break;
              } else {
                ApiErrorHandler("Sign in", xhr, context);
              }
            }
          };
          xhrtoken.send(pairs);
          //   break;
          // case 404:
          //   context.stateInfoDialog(
          //     "Verify Pin",
          //     JSON.parse(xhr.responseText).message
          //   );
          //   break;
          // default:
          //   context.stateInfoDialog(
          //     "Verify Pin",
          //     JSON.parse(xhr.responseText).message
          //   );
          //   break;
        } else {
          ApiErrorHandler(" Verify PIN", xhr, context);
        }
      }
    };

    xhr.send(JSON.stringify(verifyData));
  };

  const LoginForm = useFormik({
    initialValues: {
      username: "",
      password: "",
      firstValue: "",
      secondValue: "",
      thirdValue: "",
    },
    onSubmit: (values) => {
      Logon(values, firstDigitValue, secondDigitValue, thirdDigitValue);
    },
  });
  React.useEffect(() => {
    if (
      document.activeElement !== focusUsername &&
      document.activeElement !== focusPass
    ) {
      if (
        LoginForm.values.firstValue.length !== 1 &&
        (LoginForm.values.secondValue.length === 1 ||
          LoginForm.values.thirdValue.length === 1)
      ) {
        focusFirst.focus();
      }
      if (
        LoginForm.values.firstValue.length === 1 &&
        LoginForm.values.secondValue.length !== 1
      ) {
        focusSecond.focus();
      }

      if (
        LoginForm.values.firstValue.length === 1 &&
        LoginForm.values.secondValue.length === 1 &&
        LoginForm.values.thirdValue.length !== 1
      ) {
        focusThird.focus();
      }
      if (
        LoginForm.values.firstValue.length === 1 &&
        LoginForm.values.secondValue.length === 1 &&
        LoginForm.values.thirdValue.length === 1
      ) {
        var isFocused =
          document.activeElement === focusSecond ||
          document.activeElement === focusFirst ||
          document.activeElement === focusThird;
        if (isFocused) {
          focusButton.focus();
        }
      }
    }
  }, [LoginForm.values]);
  const verifyPinForm = useFormik({
    initialValues: {
      pinnum: "",
    },
    onSubmit: (values) => {
      getPinVerification(registerValues, retruevedUser, values.pinnum);
    },
  });
  const changePinForm = useFormik({
    initialValues: {
      OldPin: "",
      NewPin: "",
      ConfirmPIN: "",
      SecurityCode: "",
      Reference: "",
      MobileNumber: "",
      DateOfBirth: "",
    },
    onSubmit: (values) => {},
  });

  return (
    <div>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <HeaderSection title={sitename}></HeaderSection>
      <Collapse in={mainscreen}>
        <Parallax filter="primary">
          <div className={classNames(classes.container, classes.title)}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={6}
                    xl={6}
                    md={6}
                    ml-xl-auto="true"
                    className={classes.middle}
                  >
                    <h3 className={classes.shieldText}>
                      <Icon>verified_user</Icon> Make a payment towards your
                      account{" "}
                    </h3>
                    <h3 className={classes.shieldText}>
                      <Icon>verified_user</Icon> View your account online
                    </h3>

                    <h3 className={classes.shieldText}>
                      <Icon>verified_user</Icon> Choose your own payment plans
                    </h3>

                    <h3 className={classes.shieldText}>
                      <Icon>verified_user</Icon> Talk to us, we can help!
                    </h3>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    xl={6}
                    className={classes.middleInput}
                    style={{ marginTop: "-15px" }}
                  >
                    <form
                      className={classes.form}
                      onSubmit={LoginForm.handleSubmit}
                    >
                      <p
                        className={
                          classes.description + " " + classes.textCenter
                        }
                      ></p>
                      <CustomTextField
                        id="username"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        white
                        inputProps={{
                          type: "text",
                          placeholder: "Username",
                          onChange: LoginForm.handleChange,
                          name: "username",
                        }}
                      />
                      <CustomTextField
                        id="pass"
                        white
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "password",
                          autoComplete: "off",
                          placeholder: "Password",
                          onChange: LoginForm.handleChange,
                          name: "password",
                        }}
                      />
                      <GridContainer alignItems="center" justify="center">
                        <GridItem xs={4} sm={4} md={4}>
                          <h4 className={classes.digitText}>
                            {firstDigitName} DIGIT OF PIN
                          </h4>
                          <CustomTextField
                            id="firstValue"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              style: { textAlign: "center" },
                              type: "password",
                              pattern: "[0-9]*",
                              autoComplete: "off",
                              onChange: LoginForm.handleChange,
                              value: LoginForm.values.firstValue,
                              name: "firstValue",
                            }}
                          />
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <h4 className={classes.digitText}>
                            {secondDigitName} DIGIT OF PIN
                          </h4>
                          <CustomTextField
                            id="secondValue"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              style: { textAlign: "center" },
                              type: "password",
                              autoComplete: "off",
                              pattern: "[0-9]*",
                              onChange: LoginForm.handleChange,
                              value: LoginForm.values.secondValue,

                              name: "secondValue",
                            }}
                          />
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <h4 className={classes.digitText}>
                            {thirdDigitName} DIGIT OF PIN
                          </h4>
                          <CustomTextField
                            id="thirdValue"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              style: { textAlign: "center" },
                              type: "password",
                              pattern: "[0-9]*",
                              autoComplete: "off",
                              onChange: LoginForm.handleChange,
                              value: LoginForm.values.thirdValue,
                              name: "thirdValue",
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <div className={classes.centerButton}>
                        <Button
                          id="signIn"
                          disabled={loading}
                          type="submit"
                          color="primary"
                          fullWidth
                        >
                          SIGN IN
                        </Button>
                      </div>

                      <div className={classes.centerButton}>
                        {"Don’t have account? Sign up"}{" "}
                        <Link
                          href="#"
                          onClick={(e) => registerClick(null)}
                          className={classes.LinkHome}
                          color="inherit"
                        >
                          {" "}
                          here.
                        </Link>
                      </div>
                      <div className={classes.centerButton}>
                        <Link
                          href="#"
                          onClick={(e) => ResetPinAndPasswordClick(null)}
                          className={classes.LinkHome}
                          color="inherit"
                        >
                          {" "}
                          Forgotten Password/Username
                        </Link>
                      </div>
                      <div className={classes.centerButton}>
                        {"Can't Sign in? Call " + phonenumber}
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </Collapse>
      <Collapse in={registerscreen}>
        <Parallax filter="primary">
          <div className={classNames(classes.container, classes.title)}>
            <GridContainer>
              <GridItem xs={1} sm={2} md={4}></GridItem>
              <GridItem xs={10} sm={8} md={4}>
                <GridContainer alignItems="center" justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <form
                      className={classes.form}
                      onSubmit={registerForm.handleSubmit}
                    >
                      <p
                        className={
                          classes.description + " " + classes.textCenter
                        }
                      ></p>
                      <p className={classes.centerTextTitle}>
                        {resetPinscreen
                          ? "Forgotten Password/Username"
                          : "Create Account"}{" "}
                      </p>
                      <p className={classes.centerTextSubTitle}>
                        Fill in the form below to get instant access.
                      </p>
                      {registrationType == "both" ? (
                        <FormControl
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue="top"
                            value={selectedregistrationType}
                            onChange={changeRegisterType}
                          >
                            {" "}
                            <FormControlLabel
                              value="personal"
                              control={<Radio />}
                              label="Personal"
                              labelPlacement="top"
                            />
                            <FormControlLabel
                              value="business"
                              control={<Radio />}
                              label="Business"
                              labelPlacement="top"
                            />{" "}
                          </RadioGroup>
                        </FormControl>
                      ) : null}
                      <CustomTextField
                        formControlProps={{
                          fullWidth: true,
                        }}
                        white
                        onChange={registerForm.handleChange}
                        inputProps={{
                          name: "customernumber",
                          type: "text",
                          placeholder: customerCaption,
                          required: "required",
                          onChange: registerForm.handleChange,
                        }}
                      />
                      {selectedregistrationType == "business" ? (
                        <CustomTextField
                          formControlProps={{
                            fullWidth: true,
                          }}
                          white
                          inputProps={{
                            name: "email",
                            type: "email",
                            required: "required",
                            placeholder: "Email",
                            onChange: registerForm.handleChange,
                          }}
                        />
                      ) : selectedregistrationType == null ? (
                        <>
                          {" "}
                          <CustomTextField
                            formControlProps={{
                              fullWidth: true,
                            }}
                            white
                            inputProps={{
                              name: "phone",
                              type: "tel",
                              required: "required",
                              placeholder: "Mobile Phone",
                              onChange: registerForm.handleChange,
                            }}
                          />
                          <CustomTextField
                            formControlProps={{
                              fullWidth: true,
                            }}
                            white
                            inputProps={{
                              name: "dob",
                              type: "date",
                              required: "required",
                              placeholder: "Date of birth",
                              onChange: registerForm.handleChange,
                            }}
                          />
                        </>
                      ) : (
                        <CustomTextField
                          formControlProps={{
                            fullWidth: true,
                          }}
                          white
                          inputProps={{
                            name: "phone",
                            type: "tel",
                            required: "required",
                            placeholder: "Mobile Phone",
                            onChange: registerForm.handleChange,
                          }}
                        />
                      )}

                      <CustomTextField
                        formControlProps={{
                          fullWidth: true,
                        }}
                        white
                        inputProps={{
                          name: "regusername",
                          type: "text",
                          required: "required",
                          placeholder: "Choose New Username",
                          onChange: registerForm.handleChange,
                        }}
                      />
                      <CustomTextField
                        formControlProps={{
                          fullWidth: true,
                        }}
                        white
                        inputProps={{
                          type: "password",
                          autoComplete: "off",
                          name: "pass",
                          required: "required",
                          placeholder: "Choose New Password",
                          pattern:
                            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9?=.*[!@#$%^&*]{8,}$",
                          title:
                            "Minimum of 8 Characters, contain special characters, at least 1 Uppercase Letter and 1 numerical digit",
                          onChange: registerForm.handleChange,
                        }}
                      />
                      <CustomTextField
                        formControlProps={{
                          fullWidth: true,
                        }}
                        white
                        inputProps={{
                          type: "password",
                          autoComplete: "off",
                          name: "passconfirm",
                          placeholder: "Confirm New Password",
                          required: "required",
                          pattern:
                            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9?=.*[!@#$%^&*]{8,}$",
                          onChange: registerForm.handleChange,
                        }}
                      />
                      <div className={classes.centerButton}>
                        <Button type="submit" color="primary" fullWidth>
                          {resetPinscreen
                            ? "Forgotten Password/Username"
                            : "SIGN ME UP!"}
                        </Button>
                      </div>
                      <div className={classes.centerButton}>
                        <Button
                          size="sm"
                          variant="outlined"
                          onClick={loginClick}
                          color="primary"
                        >
                          Back to login
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={1} sm={2} md={4}></GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </Collapse>
      <Collapse in={verifyPin}>
        <Parallax filter="primaryDark">
          <div className={classNames(classes.container, classes.title)}>
            <GridContainer>
              <GridItem xs={1} sm={2} md={4}></GridItem>
              <GridItem xs={10} sm={8} md={4}>
                <GridContainer alignItems="center" justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <form
                      className={classes.form}
                      onSubmit={verifyPinForm.handleSubmit}
                    >
                      <p
                        className={
                          classes.description + " " + classes.textCenter
                        }
                      ></p>
                      <p className={classes.centerTextTitle}>Verify Pin</p>
                      <p className={classes.centerTextSubTitle}>
                        Please enter the PIN number you received via{" "}
                        {selectedregistrationType == null ||
                        selectedregistrationType == "personal"
                          ? "SMS"
                          : "email"}
                      </p>

                      <CustomTextField
                        formControlProps={{
                          fullWidth: true,
                        }}
                        white
                        inputProps={{
                          name: "pinnum",
                          type: "number",
                          placeholder: "PIN",
                          onChange: verifyPinForm.handleChange,
                        }}
                      />
                      <div className={classes.centerButton}>
                        <Button type="submit" color="primary" fullWidth>
                          {resetPinscreen ? "Reset Credentials" : "Sign Me Up!"}{" "}
                        </Button>
                      </div>
                      <div className={classes.centerButton}>
                        <Button
                          size="sm"
                          variant="outlined"
                          onClick={loginClick}
                          color="primary"
                        >
                          Back to login
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={1} sm={2} md={4}></GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </Collapse>
      <Collapse in={NotFound}>
        <Parallax filter="primaryDark">
          <div className={classNames(classes.container, classes.title)}>
            <GridContainer>
              <GridItem xs={1} sm={2} md={4}></GridItem>
              <GridItem xs={10} sm={8} md={4}>
                <GridContainer alignItems="center" justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <h3 className={classes.title}>The user is not Not Found</h3>
                    <Button
                      size="sm"
                      className={classes.centerButton}
                      variant="outlined"
                      onClick={loginClick}
                      color="primary"
                    >
                      Back to login
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={1} sm={2} md={4}></GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </Collapse>

      <LinkSection></LinkSection>
      <FooterSection
        AboutUs={aboutus}
        Address={address}
        WorkingHours={officehours}
        PhoneNumber={phonenumber}
        Email={email}
        companyname={companyname}
      ></FooterSection>
    </div>
  );
}
