import React from "react";
import ContextState from "./context_state_config";
import { primaryColor } from "assets/jss/xrs.js";

import { Provider } from "react-redux";
import { store } from "./store/redux/createReduxStore";

const App = () => {
  React.useEffect(() => {
    document.body.style.backgroundColor = primaryColor[14];
  }, []);
  return (
    <Provider store={store}>
      <ContextState />
    </Provider>
  );
};

export default App;
