import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import React, { useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import budgetSectionStyle from "assets/jss/xrs/views/sectionsSections/budgetSectionStyle";
import Context from "../../../utils/context";
import { primaryColor } from "assets/jss/xrs";
import { UploadDocumentsURL } from "../../../assets/jss/xrs";
import "./app.scss";
import {
  apiActions,
  getSiteDetailsActions,
  htmlActions,
  cameraActions,
} from "../../../store/redux/redux-actions/redux_actions";
import * as ACTIONS from "../../../store/actions/actions";
import Link from "@material-ui/core/Link";
import IncomeBudgetFields from "../../BudgetPage/IncomeBudgetFields.js";
import groupArray from "group-array";
import NavigateNext from "@material-ui/icons/NavigateNext";
import browserHistory from "../../../utils/history";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import BlueHeader from "./BlueHeaderSection.js";
import history from "../../../utils/history";

import * as URLS from "../../../utils/XRSAPIs";
import { element } from "prop-types";
import NumberFormat from "react-number-format";
import AlertDialog from "./Dialog.js";
import Icon from "@material-ui/core/Icon";
import Slider from "react-slick";
import { Hidden } from "@material-ui/core";
import { ApiErrorHandler } from "utils/apiErrorHandler";

import { useDispatch, useSelector } from "react-redux";

const SliderSettings = {
  infinite: false,
  arrows: false,
  centerMode: false,
  touchMove: false,

  variableWidth: false,
  dots: false,
  initialSlide: 0,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const useStyles = makeStyles(budgetSectionStyle);
export default function BudgetSection(props) {
  const context = useContext(Context);
  const classes = useStyles();
  const [step, setStep] = React.useState(0);

  const [topNav, setTopNav] = React.useState(null);
  const [showSuccess, setshowSuccess] = React.useState(false);

  const dispatch = useDispatch();
  const documents = useSelector((state) => state.CameraReducer.confirmedImages);

  const [incomesToDisplay, setincomesToDisplay] = React.useState([]);
  const [expensesToDisplay, setexpensesToDisplay] = React.useState([]);

  const [incomeMonthlyTotal, setIncomeMonthlyTotal] = React.useState(0.0);
  const [incomeYearlyTotal, setIncomeYearlyTotal] = React.useState(0.0);
  const [incomeWeeklyTotal, setIncomeWeeklyTotal] = React.useState(0.0);

  const [expenceMonthlyTotal, setExpenceMonthlyTotal] = React.useState(0.0);
  const [expenceYearlyTotal, setExpenceYearlyTotal] = React.useState(0.0);
  const [expenceWeeklyTotal, setExpenceWeeklyTotal] = React.useState(0.0);

  const [balanceMonthlyTotal, setBalanceMonthlyTotal] = React.useState(0.0);
  const [balanceYearlyTotal, setBalanceYearlyTotal] = React.useState(0.0);
  const [balanceWeeklyTotal, setBalanceWeeklyTotal] = React.useState(0.0);

  const createBudgetText = useSelector(
    (state) => state.HtmlReducer.createBudgetText
  );
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);

  const [value, setValue] = React.useState(0);
  const callPaymentPlan = () => {
    browserHistory.push("/createPayment");
  };
  const handleChange = (newValue) => {
    if (newValue == 4) {
      submitForm();
    } else {
      if (step < newValue) {
        topNav.slickNext();
      } else if (newValue < 4) {
        topNav.slickPrev();
      }
      setStep(newValue);
      window.scroll(0, 0);
    }

    let incomeslist = Object.values(incomesToDisplay).flat();
    let expencelist = Object.values(expensesToDisplay).flat();
    calculate(incomeslist, expencelist);
  };

  const calculate = (incomes, expences) => {
    let cincomeMonthlyTotal = 0;
    let cincomeYearlyTotal = 0;
    let cincomeWeeklyTotal = 0;
    incomes.forEach(function(element) {
      if (element.selectedvalue == "Monthly") {
        cincomeMonthlyTotal += parseInt(
          element.value == "" ? "0.00" : element.value
        );
        cincomeYearlyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) * 12;
        cincomeWeeklyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) / 4;
      } else if (element.selectedvalue == "Weekly") {
        cincomeMonthlyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) * 4;
        cincomeYearlyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) * 52;
        cincomeWeeklyTotal += parseInt(
          element.value == "" ? "0.00" : element.value
        );
      } else if (element.selectedvalue == "Yearly") {
        cincomeMonthlyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) / 12;
        cincomeYearlyTotal += parseInt(
          element.value == "" ? "0.00" : element.value
        );
        cincomeWeeklyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) / 52;
      }
    });
    let cexpenceMonthlyTotal = 0;
    let cexpenceYearlyTotal = 0;
    let cexpenceWeeklyTotal = 0;

    expences.forEach(function(element) {
      if (element.selectedvalue == "Monthly") {
        cexpenceMonthlyTotal += parseInt(
          element.value == "" ? "0.00" : element.value
        );
        cexpenceYearlyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) * 12;
        cexpenceWeeklyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) / 4;
      } else if (element.selectedvalue == "Weekly") {
        cexpenceMonthlyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) * 4;
        cexpenceYearlyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) * 52;
        cexpenceWeeklyTotal += parseInt(
          element.value == "" ? "0.00" : element.value
        );
      } else if (element.selectedvalue == "Yearly") {
        cexpenceMonthlyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) / 12;
        cexpenceYearlyTotal += parseInt(
          element.value == "" ? "0.00" : element.value
        );
        cexpenceWeeklyTotal +=
          parseInt(element.value == "" ? "0.00" : element.value) / 52;
      }
    });

    var cbalanceMonthlyTotal = cincomeMonthlyTotal - cexpenceMonthlyTotal;
    var cbalanceYearlyTotal = cincomeYearlyTotal - cexpenceYearlyTotal;
    var cbalanceWeeklyTotal = cincomeWeeklyTotal - cexpenceWeeklyTotal;

    setIncomeMonthlyTotal(cincomeMonthlyTotal);
    setIncomeYearlyTotal(cincomeYearlyTotal);
    setIncomeWeeklyTotal(cincomeWeeklyTotal);

    setExpenceMonthlyTotal(cexpenceMonthlyTotal);
    setExpenceYearlyTotal(cexpenceYearlyTotal);
    setExpenceWeeklyTotal(cexpenceWeeklyTotal);

    setBalanceMonthlyTotal(cbalanceMonthlyTotal);
    setBalanceYearlyTotal(cbalanceYearlyTotal);
    setBalanceWeeklyTotal(cbalanceWeeklyTotal);
  };
  const submitForm = () => {
    let incomeslist = Object.values(incomesToDisplay).flat();
    let expencelist = Object.values(expensesToDisplay).flat();
    var formData = new FormData();
    var data = {
      BudgetInformation: {
        YearlyIncomeTotal: incomeYearlyTotal.toFixed(2).toString(),
        MonthlyIncomeTotal: incomeMonthlyTotal.toFixed(2).toString(),
        WeeklyIncomeTotal: incomeWeeklyTotal.toFixed(2).toString(),
        YearlyExpenditureTotal: expenceYearlyTotal.toFixed(2).toString(),
        MonthlyExpenditureTotal: expenceMonthlyTotal.toFixed(2).toString(),
        WeeklyExpenditureTotal: expenceWeeklyTotal.toFixed(2).toString(),
        YearlySavingsTotal: balanceYearlyTotal.toFixed(2).toString(),
        MonthlySavingsTotal: balanceMonthlyTotal.toFixed(2).toString(),
        WeeklySavingsTotal: balanceWeeklyTotal.toFixed(2).toString(),
        Incomes: [],
        Expenditures: [],
      },
    };
    for (var i in incomeslist) {
      var item = incomeslist[i];

      let exists = documents.find(
        (x) => x.from == "income" && x.itemId == item.incomeId
      );

      if (exists) {
        formData.append(
          exists.name ? exists.name : exists.document.name,
          exists.document
        );
      }

      data.BudgetInformation.Incomes.push({
        IncomeName: item.incomeType,
        IncomeValue:
          item.value.toString() == "" ? "0.00" : item.value.toString(),
        IncomeFrequency: item.selectedvalue,
        AttachedFileName:
          exists === undefined
            ? ""
            : exists.name
            ? exists.name
            : exists.document.name,
      });
    }

    for (var i in expencelist) {
      var item = expencelist[i];
      let exists = documents.find(
        (x) => x.from == "expense" && x.itemId == item.expenseId
      );
      if (exists) {
        formData.append(
          exists.name ? exists.name : exists.document.name,
          exists.document
        );
      }

      data.BudgetInformation.Expenditures.push({
        ExpenditureName: item.expenseType,
        ExpenditureValue:
          item.value.toString() == "" ? "0.00" : item.value.toString(),
        ExpenditureFrequency: item.selectedvalue,
        AttachedFileName:
          exists === undefined
            ? ""
            : exists.name
            ? exists.name
            : exists.document.name,
      });
    }

    formData.append("BudgetInformation", JSON.stringify(data));
    fetch(
      UploadDocumentsURL != null
        ? UploadDocumentsURL
        : URLS.CreateBudgetAndAttachDocuments,
      {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        body: formData,
        // body: JSON.stringify(data),
      }
    )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Create Budget", data, context);
          });
          setStep(1);

          throw Error("");
        }
      })

      .then((data) => {
        setshowSuccess(true);
        dispatch(cameraActions.resetDocuments());
        getMenu();

        topNav.slickNext();

        setStep(4);
        window.scroll(0, 0);
      }, [])
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Create Budget", error, context);
        }
      });
  };
  const getMenu = () => {
    var url = URLS.GetWelcomeNavigation;
    var token = sessionStorage.getItem("access_token");

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Welcome Navigation", data, context);
          });
          throw Error("");
        }
      })
      .then((data1) => {
        dispatch(ACTIONS.set_menu(data1));

        fetch(URLS.GetWelcomeDetails, {
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              return res.json();
            } else {
              ApiErrorHandler("Welcome Details", res, context);
              throw Error("");
            }
          })
          .then((data) => {
            dispatch(htmlActions.termsQuickPay(data.customer.termsquickpay));
            dispatch(htmlActions.termsPlan(data.customer.termsplan));
            dispatch(htmlActions.termsDiscount(data.customer.termsdiscount));

            dispatch(
              htmlActions.viewOfferText(data.activeAccounts[0].viewoffertext)
            );
            dispatch(
              htmlActions.createPlanText(data.activeAccounts[0].createplantext)
            );
            dispatch(
              htmlActions.createPlantoc(data.activeAccounts[0].createplantoc)
            );
            dispatch(
              htmlActions.createBudgetText(
                data.activeAccounts[0].createbudgettext
              )
            );
            dispatch(
              htmlActions.viewAccountText(
                data.activeAccounts[0].viewacccounttext
              )
            );
            dispatch(ACTIONS.set_Customer(data.customer));

            sessionStorage.setItem("user", data.customer.cufname);

            dispatch(ACTIONS.set_Accounts(data.activeAccounts));

            dispatch(ACTIONS.set_Callbacks(data.callbackReasons));
            dispatch(ACTIONS.set_num_accounts(data.activeAccounts.length));
            if (data.activeAccounts.length == 1) {
              dispatch(ACTIONS.set_selectedAccount(data.activeAccounts[0]));
            }
            dispatch(apiActions.clear());
          })
          .catch((error) => {
            if (error.message == "") {
            } else {
              ApiErrorHandler("Welcome Details", error, context);
            }
          });
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Welcome Navigation", error, context);
        }
      });
  };

  const token = sessionStorage.getItem("access_token");

  const createIncomeData = (
    incomeCategory,
    incomeOrder,
    incomeType,
    canAttachFile,
    incomeId,

    value,
    selectedvalue,
    atachedFileName
  ) => {
    return {
      incomeCategory,
      incomeOrder,
      incomeType,
      value,
      incomeId,
      selectedvalue,
      canAttachFile,
      atachedFileName,
    };
  };
  const createExpenceData = (
    expenseCategory,
    expenseOrder,
    expenseType,
    canAttachFile,
    expenseId,
    value,
    selectedvalue,
    atachedFileName
  ) => {
    return {
      expenseCategory,
      expenseOrder,
      expenseType,
      expenseId,
      value,
      selectedvalue,
      canAttachFile,
      atachedFileName,
    };
  };
  React.useEffect(() => {
    console.log(documents);
  }, [documents]);

  React.useEffect(() => {
    //window.initSlick();
    fetch(URLS.GetBugetColumns, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Buget Columns", data, context);
          });
          throw Error("");
        }
      })
      .then((data) => {
        const IncomedataRows = [];
        const ExpencedataRows = [];

        data.incomesToDisplay.forEach((item) => {
          IncomedataRows.push(
            createIncomeData(
              item.incomeCategory,
              item.incomeOrder,
              item.incomeType,
              item.canAttachFile,
              item.incomeId,

              "",
              "Monthly",
              ""
            )
          );
        });

        data.expensesToDisplay.forEach((item) => {
          ExpencedataRows.push(
            createExpenceData(
              item.expenseCategory,
              item.expenseOrder,
              item.expenseType,
              item.canAttachFile,
              item.expenseId,
              "",
              "Monthly",
              ""
            )
          );
        });

        var groupedItems = groupArray(IncomedataRows, "incomeCategory");
        var groupedItemse = groupArray(ExpencedataRows, "expenseCategory");

        setincomesToDisplay(groupedItems);
        setexpensesToDisplay(groupedItemse);
      }, [])
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Buget Columns", error, context);
        }
      });
  }, []);

  function handleInputChange(event, index, cat) {
    incomesToDisplay[cat][index].value = event.target.value;
    setincomesToDisplay(incomesToDisplay);
  }
  function handleInputSelectedChange(event, index, cat) {
    incomesToDisplay[cat][index].selectedvalue = event.target.value;
    setincomesToDisplay(incomesToDisplay);
  }

  function handleInputExSelectedChange(event, index, cat) {
    expensesToDisplay[cat][index].selectedvalue = event.target.value;
    setexpensesToDisplay(expensesToDisplay);
  }

  function handleInputExChange(event, index, cat) {
    expensesToDisplay[cat][index].value = event.target.value;
    setexpensesToDisplay(expensesToDisplay);
  }

  return (
    <div>
      <BlueHeader></BlueHeader>

      <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
        <CardBody>
          <Slider
            ref={(slider) => {
              setTopNav(slider);
            }}
            {...SliderSettings}
          >
            <div key={0} className="col">
              <div className={step == 0 ? "step-cell active " : "step-cell"}>
                <a onClick={() => handleChange(0)}>Instructions</a>
                <div className="arrow-outer">
                  <div className="arrow-inner"></div>
                </div>
              </div>
            </div>

            <div key={1} className="col">
              <div className={step == 1 ? "step-cell active" : "step-cell"}>
                <div className="arrow-outer arrow-outer-before">
                  <div className="arrow-inner"></div>
                </div>

                <a onClick={() => handleChange(1)}>Incomes</a>

                <div className="arrow-outer">
                  <div className="arrow-inner"></div>
                </div>
              </div>
            </div>

            <div key={2} className="col">
              <div className={step == 2 ? "step-cell active" : "step-cell"}>
                <div className="arrow-outer arrow-outer-before">
                  <div className="arrow-inner"></div>
                </div>

                <a onClick={() => handleChange(2)}>Expenditure</a>

                <div className="arrow-outer">
                  <div className="arrow-inner"></div>
                </div>
              </div>
            </div>

            <div key={3} className="col">
              <div className={step == 3 ? "step-cell active" : "step-cell"}>
                <div className="arrow-outer arrow-outer-before">
                  <div className="arrow-inner"></div>
                </div>

                <a onClick={() => handleChange(3)}>Finalise</a>

                <div className="arrow-outer">
                  <div className="arrow-inner"></div>
                </div>
              </div>
            </div>

            <div key={4} className="col">
              <div className={step == 4 ? "step-cell active" : "step-cell"}>
                <div className="arrow-outer arrow-outer-before">
                  <div className="arrow-inner"></div>
                </div>

                <a onClick={() => handleChange(4)}>Summary</a>

                <div className="arrow-outer">
                  <div className="arrow-inner"></div>
                </div>
              </div>
            </div>
          </Slider>

          <div
            style={{ display: step == 0 ? "block" : "none" }}
            className="budget-cell d-flex flex-column"
          >
            <div className="center-container-940">
              {createBudgetText}
              <div className={classes.button}>
                <Button
                  onClick={() => {
                    handleChange(1);
                  }}
                  color="primary"
                  icon={<NavigateNext />}
                  classNames={classes.button}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{ display: step == 1 ? "block" : "none" }}
            className="budget-cell d-flex flex-column"
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                if (event.target.checkValidity()) {
                  handleChange(2);
                }
              }}
              className="center-container-940"
            >
              <h3>Enter your incomes in the area below</h3>

              {Object.keys(incomesToDisplay).map(function(cat, index) {
                return (
                  <div key={index}>
                    <h4 className={classes.Title}>{cat}</h4>
                    {incomesToDisplay[cat].map(function(item, index2) {
                      return (
                        <IncomeBudgetFields
                          key={item.incomeType}
                          name={item.incomeType}
                          incomeId={item.incomeId}
                          from="income"
                          canAttachFile={item.canAttachFile}
                          onhandleChange={(e) =>
                            handleInputChange(e, index2, cat)
                          }
                          handleSelectedChange={(e) =>
                            handleInputSelectedChange(e, index2, cat)
                          }
                          value={item.value}
                          selectedvalue={item.selectedvalue}
                        ></IncomeBudgetFields>
                      );
                    })}
                  </div>
                );
              })}
              <div className={classes.button}>
                <Button
                  onClick={() => handleChange(0)}
                  color="primary"
                  icon={<NavigateNext />}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  icon={<NavigateNext />}
                  className={classes.button}
                >
                  Next
                </Button>
              </div>
            </form>
          </div>

          <div
            style={{ display: step == 2 ? "block" : "none" }}
            className="budget-cell d-flex flex-column"
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                if (event.target.checkValidity()) {
                  handleChange(3);
                }
              }}
              className="center-container-940"
            >
              <h3>Expenditure</h3>

              {Object.keys(expensesToDisplay).map(function(cat, index) {
                return (
                  <div key={index}>
                    <h4 className={classes.Title}>{cat}</h4>

                    {expensesToDisplay[cat].map(function(item, index2) {
                      return (
                        <IncomeBudgetFields
                          key={item.expenseType}
                          name={item.expenseType}
                          value={item.value}
                          expenseId={item.expenseId}
                          from="expense"
                          canAttachFile={item.canAttachFile}
                          onhandleChange={(e) =>
                            handleInputExChange(e, index2, cat)
                          }
                          handleSelectedChange={(e) =>
                            handleInputExSelectedChange(e, index2, cat)
                          }
                          selectedvalue={item.selectedvalue}
                        ></IncomeBudgetFields>
                      );
                    })}
                  </div>
                );
              })}
              <div className={classes.button}>
                <Button
                  onClick={() => handleChange(1)}
                  color="primary"
                  icon={<NavigateNext />}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  icon={<NavigateNext />}
                  className={classes.button}
                >
                  Next
                </Button>
              </div>
            </form>
          </div>

          <div
            style={{ display: step == 3 ? "block" : "none" }}
            className="budget-cell d-flex flex-column"
          >
            <div className="center-container-940">
              <h3 className={classes.Title}>Finalise Budget</h3>
              <div className="center-container-940">
                <Hidden smDown>
                  <GridContainer>
                    <GridItem md={6}>
                      <br></br>

                      <GridContainer style={{ marginTop: "7px" }}>
                        <GridItem md={6} className={classes.Summary}>
                          Income Totals
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={6} className={classes.Summary}>
                          Expenditure Totals
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={6} className={classes.Summary}>
                          Your available money
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem md={2}>
                      <h4 className={classes.TitleTop}>Yearly</h4>
                      <div>
                        <GridContainer>
                          <GridItem md={12} className={classes.FinaliseMoney}>
                            <NumberFormat
                              value={incomeYearlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={12} className={classes.FinaliseMoney}>
                            <NumberFormat
                              value={expenceYearlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            md={12}
                            className={
                              balanceYearlyTotal > 0
                                ? classes.MoneyGreen
                                : balanceMonthlyTotal < 0
                                ? classes.MoneyRed
                                : classes.Money
                            }
                          >
                            <NumberFormat
                              value={balanceYearlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridItem>
                    <GridItem md={2}>
                      <h4 className={classes.TitleTop}>Monthly</h4>
                      <div>
                        <GridContainer>
                          <GridItem md={12} className={classes.FinaliseMoney}>
                            <NumberFormat
                              value={incomeMonthlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={12} className={classes.FinaliseMoney}>
                            <NumberFormat
                              value={expenceMonthlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            md={12}
                            className={
                              balanceMonthlyTotal > 0
                                ? classes.MoneyGreen
                                : balanceMonthlyTotal < 0
                                ? classes.MoneyRed
                                : classes.Money
                            }
                          >
                            <NumberFormat
                              value={balanceMonthlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridItem>
                    <GridItem md={2}>
                      <h4 className={classes.TitleTop}>Weekly</h4>
                      <div>
                        <GridContainer>
                          <GridItem md={12} className={classes.FinaliseMoney}>
                            <NumberFormat
                              value={incomeWeeklyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={12} className={classes.FinaliseMoney}>
                            <NumberFormat
                              value={expenceWeeklyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            md={12}
                            className={
                              balanceWeeklyTotal > 0
                                ? classes.MoneyGreen
                                : balanceMonthlyTotal < 0
                                ? classes.MoneyRed
                                : classes.Money
                            }
                          >
                            <NumberFormat
                              value={balanceWeeklyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridItem>
                  </GridContainer>
                </Hidden>
                <Hidden mdUp>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <h4 className={classes.Title}>Income Totals</h4>
                      <div>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Yearly</span>
                          </GridItem>
                          <GridItem xs={6} sm={6} className={classes.Money}>
                            <NumberFormat
                              value={incomeYearlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Monthly</span>
                          </GridItem>
                          <GridItem xs={6} sm={6} className={classes.Money}>
                            <NumberFormat
                              value={incomeMonthlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Weekly</span>
                          </GridItem>
                          <GridItem xs={6} sm={6} className={classes.Money}>
                            <NumberFormat
                              value={incomeWeeklyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <h4 className={classes.Title}>Expenditure Totals</h4>
                      <div>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Yearly</span>
                          </GridItem>
                          <GridItem xs={6} sm={6} className={classes.Money}>
                            <NumberFormat
                              value={expenceYearlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Monthly</span>
                          </GridItem>
                          <GridItem xs={6} sm={6} className={classes.Money}>
                            <NumberFormat
                              value={expenceMonthlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Weekly</span>
                          </GridItem>
                          <GridItem xs={6} sm={6} className={classes.Money}>
                            <NumberFormat
                              value={expenceWeeklyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <h4 className={classes.Title}>Your available Money</h4>
                      <div>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Yearly</span>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            className={
                              balanceYearlyTotal > 0
                                ? classes.MoneyGreen
                                : balanceMonthlyTotal < 0
                                ? classes.MoneyRed
                                : classes.Money
                            }
                          >
                            <NumberFormat
                              value={balanceYearlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Monthly</span>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            className={
                              balanceMonthlyTotal > 0
                                ? classes.MoneyGreen
                                : balanceMonthlyTotal < 0
                                ? classes.MoneyRed
                                : classes.Money
                            }
                          >
                            <NumberFormat
                              value={balanceMonthlyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <span className={classes.Summary}>Weekly</span>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            className={
                              balanceWeeklyTotal > 0
                                ? classes.MoneyGreen
                                : balanceMonthlyTotal < 0
                                ? classes.MoneyRed
                                : classes.Money
                            }
                          >
                            <NumberFormat
                              value={balanceWeeklyTotal}
                              displayType={"text"}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={currency}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridItem>
                  </GridContainer>
                </Hidden>
                <div className="center-container-940">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <h4 className={classes.Title}>Income Totals</h4>
                      {Object.keys(incomesToDisplay).map(function(cat, index) {
                        return (
                          <div key={index}>
                            <h4 className={classes.SubTitle}>{cat}</h4>
                            {incomesToDisplay[cat].map(function(item, index2) {
                              return (
                                <div>
                                  <GridContainer key={index2}>
                                    <GridItem xs={12} sm={12} md={6}>
                                      <span className={classes.Summary}>
                                        {item.incomeType}
                                      </span>
                                    </GridItem>
                                    <Hidden mdUp>
                                      <GridItem
                                        className={classes.Money}
                                        xs={12}
                                        sm={12}
                                        md={3}
                                      >
                                        {item.selectedvalue}
                                      </GridItem>
                                    </Hidden>
                                    <GridItem
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      className={classes.Money}
                                    >
                                      <NumberFormat
                                        value={
                                          item.value == "" ? "0.00" : item.value
                                        }
                                        displayType={"text"}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        prefix={currency}
                                      />
                                    </GridItem>
                                    <Hidden smDown>
                                      <GridItem
                                        className={classes.Money}
                                        xs={12}
                                        sm={12}
                                        md={3}
                                      >
                                        {item.selectedvalue}
                                      </GridItem>
                                    </Hidden>
                                  </GridContainer>
                                  <Hidden mdUp>
                                    {" "}
                                    <hr className={classes.Line} />
                                  </Hidden>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <h4 className={classes.Title}>Expense Totals</h4>
                      {Object.keys(expensesToDisplay).map(function(cat, index) {
                        return (
                          <div key={index}>
                            <h4 className={classes.SubTitle}>{cat}</h4>

                            {expensesToDisplay[cat].map(function(item, index2) {
                              return (
                                <div>
                                  <GridContainer key={index2}>
                                    <GridItem xs={12} sm={12} md={6}>
                                      <span className={classes.Summary}>
                                        {" "}
                                        {item.expenseType}
                                      </span>
                                    </GridItem>
                                    <Hidden mdUp>
                                      <GridItem
                                        className={classes.Money}
                                        xs={12}
                                        sm={12}
                                        md={3}
                                      >
                                        {item.selectedvalue}
                                      </GridItem>
                                    </Hidden>
                                    <GridItem
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      className={classes.Money}
                                    >
                                      <NumberFormat
                                        value={
                                          item.value == "" ? "0.00" : item.value
                                        }
                                        displayType={"text"}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        prefix={currency}
                                      />
                                    </GridItem>
                                    <Hidden smDown>
                                      <GridItem
                                        className={classes.Money}
                                        xs={12}
                                        sm={12}
                                        md={3}
                                      >
                                        {item.selectedvalue}
                                      </GridItem>
                                    </Hidden>
                                  </GridContainer>
                                  <Hidden mdUp>
                                    {" "}
                                    <hr className={classes.Line} />
                                  </Hidden>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
              <p>
                Finalising the budget will save the details on our system. If
                you are unsure that the details are correct, please go back and
                amend before finalising
              </p>
              <div className={classes.button}>
                <Button
                  onClick={() => handleChange(2)}
                  color="primary"
                  icon={<NavigateNext />}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  onClick={() => handleChange(4)}
                  color="primary"
                  icon={<NavigateNext />}
                  className={classes.button}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{ display: step == 4 ? "block" : "none" }}
            className="budget-cell d-flex flex-column"
          >
            <div className="center-container-940">
              <h3>Summary</h3>
              <Hidden smDown>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4 className={classes.Title}>Income Totals</h4>
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Yearly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.Money}
                        >
                          <NumberFormat
                            value={incomeYearlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Monthly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.Money}
                        >
                          <NumberFormat
                            value={incomeMonthlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Weekly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.Money}
                        >
                          <NumberFormat
                            value={incomeWeeklyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4 className={classes.Title}>Expenditure Totals</h4>
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Yearly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.Money}
                        >
                          <NumberFormat
                            value={expenceYearlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Monthly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.Money}
                        >
                          <NumberFormat
                            value={expenceMonthlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Weekly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.Money}
                        >
                          <NumberFormat
                            value={expenceWeeklyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4 className={classes.Title}>Your available Money</h4>
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Yearly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={
                            balanceYearlyTotal > 0
                              ? classes.MoneyGreen
                              : balanceMonthlyTotal < 0
                              ? classes.MoneyRed
                              : classes.Money
                          }
                        >
                          <NumberFormat
                            value={balanceYearlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Monthly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={
                            balanceMonthlyTotal > 0
                              ? classes.MoneyGreen
                              : balanceMonthlyTotal < 0
                              ? classes.MoneyRed
                              : classes.Money
                          }
                        >
                          <NumberFormat
                            value={balanceMonthlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <span className={classes.Summary}>Weekly</span>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={
                            balanceWeeklyTotal > 0
                              ? classes.MoneyGreen
                              : balanceMonthlyTotal < 0
                              ? classes.MoneyRed
                              : classes.Money
                          }
                        >
                          <NumberFormat
                            value={balanceWeeklyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                </GridContainer>
              </Hidden>
              <Hidden mdUp>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <h4 className={classes.Title}>Income Totals</h4>
                    <div>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Yearly</span>
                        </GridItem>
                        <GridItem xs={6} sm={6} className={classes.Money}>
                          <NumberFormat
                            value={incomeYearlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Monthly</span>
                        </GridItem>
                        <GridItem xs={6} sm={6} className={classes.Money}>
                          <NumberFormat
                            value={incomeMonthlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Weekly</span>
                        </GridItem>
                        <GridItem xs={6} sm={6} className={classes.Money}>
                          <NumberFormat
                            value={incomeWeeklyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <h4 className={classes.Title}>Expenditure Totals</h4>
                    <div>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Yearly</span>
                        </GridItem>
                        <GridItem xs={6} sm={6} className={classes.Money}>
                          <NumberFormat
                            value={expenceYearlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Monthly</span>
                        </GridItem>
                        <GridItem xs={6} sm={6} className={classes.Money}>
                          <NumberFormat
                            value={expenceMonthlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Weekly</span>
                        </GridItem>
                        <GridItem xs={6} sm={6} className={classes.Money}>
                          <NumberFormat
                            value={expenceWeeklyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <h4 className={classes.Title}>Your available Money</h4>
                    <div>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Yearly</span>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          className={
                            balanceYearlyTotal > 0
                              ? classes.MoneyGreen
                              : balanceMonthlyTotal < 0
                              ? classes.MoneyRed
                              : classes.Money
                          }
                        >
                          <NumberFormat
                            value={balanceYearlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Monthly</span>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          className={
                            balanceMonthlyTotal > 0
                              ? classes.MoneyGreen
                              : balanceMonthlyTotal < 0
                              ? classes.MoneyRed
                              : classes.Money
                          }
                        >
                          <NumberFormat
                            value={balanceMonthlyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={6} sm={6}>
                          <span className={classes.Summary}>Weekly</span>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          className={
                            balanceWeeklyTotal > 0
                              ? classes.MoneyGreen
                              : balanceMonthlyTotal < 0
                              ? classes.MoneyRed
                              : classes.Money
                          }
                        >
                          <NumberFormat
                            value={balanceWeeklyTotal}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                </GridContainer>
              </Hidden>
              {showSuccess ? (
                <ul class="list-unstyled">
                  {" "}
                  <li>
                    {" "}
                    <p className={classes.Submited}>
                      Your budget has been submitted
                    </p>
                  </li>
                </ul>
              ) : null}

              <div
                className={
                  balanceYearlyTotal > 0
                    ? classes.showSummary
                    : classes.hideSummary
                }
              >
                <p>
                  Please proceed to Create a Payment Plan by clicking on
                  <div className={classes.linkButton}>
                    <Button onClick={callPaymentPlan} color="primary">
                      <Icon className="fa fa-credit-card" />
                    </Button>
                  </div>
                  button{" "}
                </p>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
