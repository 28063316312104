import React, { useContext } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import FooterSection from "../SectionsPage/Sections/FooterSection.js";
import HeaderSection from "../SectionsPage/Sections/HeaderSection.js";
import LinkSection from "../SectionsPage/Sections/LinksSection.js";
import Accordion from "components/Accordion/Accordion.js";
import * as URLS from "../../utils/XRSAPIs";
import Context from "../../utils/context";
import ReactHtmlParser from "react-html-parser";
import { getSiteDetailsActions } from "../../store/redux/redux-actions/redux_actions";
import * as ACTIONS from "../../store/actions/actions";
import { ApiErrorHandler } from "utils/apiErrorHandler";

import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(landingPageStyle);

export default function FaqPage() {
  const context = useContext(Context);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [sitename, setSitename] = React.useState("");
  const [companyname, setCompanyname] = React.useState("");
  const [aboutus, setAboutus] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [officehours, setOfficehours] = React.useState("");
  const [phonenumber, setPhonenumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const stateSite = useSelector((state) => state.SiteReducer.site);

  const createData = (title, content) => {
    return { title, content };
  };
  const [rowdata, setRowdata] = React.useState([]);
  const [site, setSite] = React.useState("");

  const ReadContext = () => {
    const dataRows = [];
    var data = stateSite.faqs;
    if (data != "NoAccounts") {
      var i = 1;
      data.forEach((item) => {
        dataRows.push(
          createData(
            ReactHtmlParser(item.header),
            ReactHtmlParser(item.details)
          )
        );
        i++;
        setRowdata(dataRows);
      });
    }
  };

  React.useEffect(() => {
    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Site Detatils", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        dispatch(getSiteDetailsActions.logo(response.logoimageurl));
        dispatch(getSiteDetailsActions.footerImage(response.footerimageurl));
        dispatch(ACTIONS.set_Site(response));
        setSitename(response.sitename);
        setCompanyname(response.companyname);
        setAboutus(response.aboutus);
        setOfficehours(response.officehours);
        setPhonenumber(response.phonenumber);
        setAddress(response.address);
        setEmail(response.email);

        setTimeout(() => {
          setSite(stateSite);
          ReadContext();
        }, 100);
      })

      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
  }, [page]);
  const classes = useStyles();

  return (
    <div>
      <HeaderSection title={sitename}></HeaderSection>
      <Parallax
        filter="primary"
        image={require("assets/img/header-bg-hero.png")}
      >
        <div className={classNames(classes.container, classes.title)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>Frequently Asked Questions</h3>
              <Accordion
                defaultExpanded="true"
                style={{ height: "1000px" }}
                active={0}
                collapses={rowdata}
              />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <LinkSection></LinkSection>
      <FooterSection
        AboutUs={aboutus}
        Address={address}
        WorkingHours={officehours}
        PhoneNumber={phonenumber}
        Email={email}
        companyname={companyname}
      ></FooterSection>
    </div>
  );
}
