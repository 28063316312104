import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import blueHeaderSectionStyle from "assets/jss/xrs/views/sectionsSections/blueHeaderSectionStyle";
import Context from "../../../utils/context";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NumberFormat from "react-number-format";
import * as URLS from "../../../utils/XRSAPIs";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const useStyles = makeStyles(blueHeaderSectionStyle);
export default function BlueHeaderSection(props) {
  const context = useContext(Context);
  const classes = useStyles();
  const [ballance, setBallance] = React.useState(0);
  const [site, setSite] = React.useState("");
  const token = sessionStorage.getItem("access_token");
  const [phone, setPhone] = React.useState("");
  const stateSite = useSelector((state) => state.SiteReducer.site);
  const stateSelectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);
  const deptphonenumber = useSelector(
    (state) => state.GetSiteDetailsReducer.deptphonenumber
  );

  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);

  React.useEffect(() => {
    if (stateSelectedAccount == "NoAccount") {
      setBallance(stateCustomer.totalbalance);
    } else {
      setBallance(stateSelectedAccount.balance);
    }
    debugger;
    if (deptphonenumber != "") {
      setPhone(deptphonenumber);
    } else {
      setPhone(stateSite.phonenumber);
    }
  }, []);

  return (
    <Card className={classes.CardStyle}>
      <CardBody style={{ padding: "0px" }}>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <h4 style={{ left: "10px" }} className={classes.white}>
              {" "}
              Total Balance:{" "}
              <NumberFormat
                value={ballance}
                displayType={"text"}
                fixedDecimalScale={true}
                thousandSeparator={true}
                decimalScale={2}
                prefix={currency}
              />
            </h4>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <h4 className={classes.whiteRight}>
              Call us now:{" "}
              {isMobile ? (
                <a className={classes.whiteRight} href={"tel:" + phone}>
                  {phone}
                </a>
              ) : (
                <a className={classes.whiteRight}>{phone}</a>
              )}
            </h4>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}
