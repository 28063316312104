import { GET_SITE_DETAILS_ACTION_TYPES } from "../redux-actions/redux_action_types";

export const initialState = {
  logoimageurl: "",
  footerImageUrl: "",
  currency: "",
  paymentProvider: "",
  deptphonenumber: "",
};

export const GetSiteDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SITE_DETAILS_ACTION_TYPES.LOGO:
      return {
        ...state,
        logoimageurl: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.DEPT_NUM:
      return {
        ...state,
        deptphonenumber: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.FOOTER_IMAGE:
      return {
        ...state,
        footerImageUrl: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.PAYMENT_PROVIDER:
      return {
        ...state,
        paymentProvider: action.payload,
      };

    default:
      return state;
  }
};
