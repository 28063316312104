import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import fnmoment from "moment/min/moment-with-locales";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Context from "../../../utils/context";
import * as URLS from "../../../utils/XRSAPIs";
import * as ACTIONS from "../../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import { primaryColor } from "assets/jss/xrs.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import BlueHeader from "./BlueHeaderSection.js";
import ReactHtmlParser from "react-html-parser";
import Parallax from "components/Parallax/Parallax.js";
import { Collapse } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import {
  cameraActions,
  documentsActions,
  htmlActions,
  apiActions,
} from "../../../store/redux/redux-actions/redux_actions";
import history from "../../../utils/history";

import { DataGrid, getThemePaletteMode } from "@material-ui/data-grid";
import { createMuiTheme, darken, lighten } from "@material-ui/core/styles";

import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";

import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import PdfViewer from "components/PdfViewer/PdfViewer";

const defaultTheme = createMuiTheme();
const useStyles = makeStyles(
  (theme) => {
    const getBackgroundColor = (color) =>
      getThemePaletteMode(theme.palette) === "dark"
        ? darken(color, 0.6)
        : lighten(color, 0.6);

    const getHoverBackgroundColor = (color) =>
      getThemePaletteMode(theme.palette) === "dark"
        ? darken(color, 0.5)
        : lighten(color, 0.5);

    return {
      root: {
        "& .rowStyle--READ": {
          fontWeight: 400,
          "&:hover": {},
        },
        "& .rowStyle--UNREAD": {
          fontWeight: 700,
          backgroundColor: getBackgroundColor("#BEBEBE"),
          "&:hover": {},
        },
        "& .headerStyle": {
          border: "none",
        },
        "& .cellStyle": {
          borderStyle: "none",
        },
      },
    };
  },
  { defaultTheme }
);

export default function MessagesSection(props) {
  const context = useContext(Context);
  const classes = useStyles();
  const createData = (title, content) => {
    return { title, content };
  };
  const dispatch = useDispatch();

  const columnsDesktop = [
    {
      field: "Status",
      headerName: "Status",
      width: 130,
      hide: true,
      headerClassName: "headerStyle",
      cellClassName: "cellStyle",
    },
    {
      field: "ReceivedAt",
      headerName: "Date/Time",
      flex: 1,
      headerClassName: "headerStyle",
      cellClassName: "cellStyle",
    },
    //{
    //  field: 'CustomerRecNo', headerName: 'First name', width: 130 },
    {
      field: "Reference",
      headerName: "Ref",
      flex: 1,
      headerClassName: "headerStyle",
      cellClassName: "cellStyle",
    },
    {
      field: "Account",
      headerName: "Account",
      flex: 1,
      headerClassName: "headerStyle",
      cellClassName: "cellStyle",
    },
    // {
    //   field: "Type",
    //   headerName: "Type",
    //   flex: 1,
    //   headerClassName: "headerStyle",
    //   cellClassName: "cellStyle",
    // },
    {
      field: "HasAttachment",
      headerName: "Attachment",
      flex: 1,
      headerClassName: "headerStyle",
      cellClassName: "cellStyle",
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {
              (params.row.HasAttachment = params.row.HasAttachment ? (
                <AttachFileIcon />
              ) : null)
            }
          </div>
        );
      },
    },
  ];
  const columnsMobile = [
    {
      field: "ReceivedAt",
      headerName: "Date/Time",
      flex: 1,
      headerClassName: "headerStyle",
      cellClassName: "cellStyle",
    },
    {
      field: "Reference",
      headerName: "Ref",
      flex: 1,
      headerClassName: "headerStyle",
      cellClassName: "cellStyle",
    },
    // {
    //   field: "CustomerRecNo",
    //   headerName: "First name",
    //   width: 130,
    // },

    // {
    //   field: "Type",
    //   headerName: "Type",
    //   flex: 1,
    //   headerClassName: "headerStyle",
    //   cellClassName: "cellStyle",
    // },
    {
      field: "HasAttachment",
      headerName: "Attachment",
      flex: 0.3,
      headerClassName: "headerStyle",
      cellClassName: "cellStyle",
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {
              (params.row.HasAttachment = params.row.HasAttachment ? (
                <AttachFileIcon />
              ) : null)
            }
          </div>
        );
      },
    },
  ];

  const token = sessionStorage.getItem("access_token");

  const [rowdata, setRowdata] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const [site, setSite] = React.useState("");
  const stateSite = useSelector((state) => state.SiteReducer.site);
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);
  const ReadContext = () => {
    setSite(stateSite);
    const dataRows = [];
    var data = stateSite.faqs;
    if (data != "NoAccounts") {
      var i = 1;
      data.forEach((item) => {
        dataRows.push(
          createData(
            ReactHtmlParser(item.header),
            ReactHtmlParser(item.details)
          )
        );
        i++;
      });
    }
  };
  const getMenu = () => {
    var url = URLS.GetWelcomeNavigation;
    var token = sessionStorage.getItem("access_token");

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Welcome Navigation", data, context);
          });
          throw Error("");
        }
      })
      .then((data1) => {
        dispatch(ACTIONS.set_menu(data1));

        fetch(URLS.GetWelcomeDetails, {
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              return res.json();
            } else {
              ApiErrorHandler("Welcome Details", res, context);
              throw Error("");
            }
          })
          .then((data) => {
            dispatch(htmlActions.termsQuickPay(data.customer.termsquickpay));
            dispatch(htmlActions.termsPlan(data.customer.termsplan));
            dispatch(htmlActions.termsDiscount(data.customer.termsdiscount));

            dispatch(
              htmlActions.viewOfferText(data.activeAccounts[0].viewoffertext)
            );
            dispatch(
              htmlActions.createPlanText(data.activeAccounts[0].createplantext)
            );
            dispatch(
              htmlActions.createPlantoc(data.activeAccounts[0].createplantoc)
            );
            dispatch(
              htmlActions.createBudgetText(
                data.activeAccounts[0].createbudgettext
              )
            );
            dispatch(
              htmlActions.viewAccountText(
                data.activeAccounts[0].viewacccounttext
              )
            );
            dispatch(ACTIONS.set_Customer(data.customer));

            sessionStorage.setItem("user", data.customer.cufname);

            dispatch(ACTIONS.set_Accounts(data.activeAccounts));

            dispatch(ACTIONS.set_Callbacks(data.callbackReasons));
            dispatch(ACTIONS.set_num_accounts(data.activeAccounts.length));
            if (data.activeAccounts.length == 1) {
              dispatch(ACTIONS.set_selectedAccount(data.activeAccounts[0]));
            }
            dispatch(apiActions.clear());
          })
          .catch((error) => {
            if (error.message == "") {
            } else {
              ApiErrorHandler("Welcome Details", error, context);
            }
          });
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Welcome Navigation", error, context);
        }
      });
    getSiteDetails();
  };
  const getSiteDetails = () => {
    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Site Detatils", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        dispatch(ACTIONS.set_Site(response));

        ReadContext();
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
    fetch(URLS.GetUserMessages, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Messages", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        let rows = response.map((x) => {
          x.ReceivedAt = fnmoment(x.ReceivedAt).format("L HH:MM");

          return x;
        });
        setRowdata(rows);
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
  };
  React.useEffect(() => {
    getSiteDetails();
    fnmoment.locale(window.navigator.language.toLowerCase());
  }, []);
  const [showDetails, setShowDetails] = React.useState(false);
  const onRowEvent = (row) => {
    console.log(row);

    fetch(
      URLS.GetUserMessageDetails +
        new URLSearchParams({
          MessageId: row.id,
        }),
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Message Details", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        setSelectedRow(response);
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Message Details", error, context);
        }
      });
    getMenu();
    setShowDetails(true);
  };
  const handleBack = () => {
    setShowDetails(false);
  };
  const handleView = () => {
    let pdf = selectedRow.MimeType == "PDF" ? true : false;
    if (pdf && isMobile) {
      window.open(selectedRow.Attachment, "_blank");
    }
    if (!pdf) {
      const url = window.URL.createObjectURL(selectedRow.Attachment);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = "todo-1.json";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      alert("your file has downloaded!"); // or you know, something with better UX...
    } else {
      dispatch(
        documentsActions.viewDocumet({
          preview: selectedRow.Attachment,
          pdf: pdf,
        })
      );
      openFullscreen();
    }
  };
  var elem = document.documentElement;

  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  return (
    <div>
      <BlueHeader></BlueHeader>
      <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
        <Collapse in={!showDetails}>
          <CardBody style={isMobile ? { padding: "0px" } : {}}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.root}>
                  <h4
                    class="text-center h-xlight\"
                    style={
                      isMobile
                        ? { paddingLeft: "10px", color: "#1f6b9f" }
                        : { color: "#1f6b9f" }
                    }
                  >
                    <b>Message inbox</b>
                  </h4>
                  <DataGrid
                    onRowClick={(row) => onRowEvent(row)}
                    // getRowClassName={(params) => {
                    //   ;
                    //   let tet = `rowStyle--${
                    //     params.row.Status == "READ" ? ".Mui-selected" : null
                    //   }`;

                    //   return ".Mui-selected";
                    // }}
                    getRowClassName={(params) =>
                      `rowStyle--${params.getValue(
                        params.row.MessageId,
                        "Status"
                      )}`
                    }
                    getRowId={(row) => row.MessageId}
                    density="compact"
                    autoHeight="true"
                    rows={rowdata}
                    columns={isMobile ? columnsMobile : columnsDesktop}
                    pageSize={10}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Collapse>
        <Collapse in={showDetails}>
          <CardBody style={isMobile ? { padding: "15px" } : {}}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.root}>
                  {selectedRow !== null ? (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <h4
                              class="text-center h-xlight\"
                              style={{ color: "#1f6b9f" }}
                            >
                              <b>
                                {" "}
                                {selectedRow.Reference}, account number:
                                {selectedRow.CustomerRecNo}
                              </b>
                            </h4>
                          </GridItem>
                          <GridItem
                            style={{ paddingBottom: "0px" }}
                            xs={12}
                            sm={12}
                            md={6}
                          >
                            <p
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {fnmoment(selectedRow.ReceivedAt).format(
                                "L HH:MM"
                              )}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <p>{selectedRow.Body}</p>
                      </GridItem>
                      {selectedRow.HasAttachment ? (
                        <>
                          <GridItem
                            style={{ textAlign: "right" }}
                            xs={6}
                            sm={6}
                            md={6}
                          >
                            <Button onClick={handleBack} color="primary">
                              Back
                            </Button>
                          </GridItem>
                          <GridItem
                            style={{ textAlign: "left" }}
                            xs={6}
                            sm={6}
                            md={6}
                          >
                            <Button onClick={handleView} color="primary">
                              View {selectedRow.MimeType}
                            </Button>
                          </GridItem>
                        </>
                      ) : (
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Button onClick={handleBack} color="primary">
                            Back
                          </Button>{" "}
                        </GridItem>
                      )}
                    </GridContainer>
                  ) : null}
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
}
