import React from "react";
import styles from "assets/jss/xrs/components/fabCameraStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

import { cameraActions } from "../../store/redux/redux-actions/redux_actions";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles(styles);
export default function FabOpenedCamera({ changeCamera, capturePicture }) {
  const classes = useStyles();
  const openCamera = useSelector((state) => state.CameraReducer.openFrom);
  const image = useSelector((state) => state.CameraReducer.capturedImage);
  const documents = useSelector((state) => state.CameraReducer.confirmedImages);

  const [numberOfCameras, setNumberOfCameras] = React.useState(0);
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
  React.useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true });
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      var amount = 0;
      devices.map((item) => {
        if (item.kind === "videoinput") {
          amount++;
        }
      });

      setNumberOfCameras(amount);
    });
  }, []);

  const dispatch = useDispatch();
  if (openCamera === "" ? false : true) {
    if (image === "") {
      return (
        <>
          <div
            onClick={() => {
              capturePicture();
            }}
            className={classes.fabCameraContainer}
          >
            <div className={classes.fab + " " + classes.fabIconHolder}>
              <i>
                <CameraAltIcon></CameraAltIcon>
              </i>
            </div>
          </div>
          <div
            onClick={() => {
              dispatch(cameraActions.openCamera(""));
              closeFullscreen();
            }}
            className={classes.fabReturnContainer}
          >
            <div className={classes.fabReturn + " " + classes.fabIconHolder}>
              <i>
                <KeyboardReturnIcon></KeyboardReturnIcon>
              </i>
            </div>
          </div>
          {numberOfCameras > 1 ? (
            <div
              onClick={() => {
                changeCamera();
              }}
              className={classes.fabChangeCameraContainer}
            >
              <div className={classes.fabReturn + " " + classes.fabIconHolder}>
                <i>
                  <FlipToFrontIcon></FlipToFrontIcon>
                </i>
              </div>
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <div
            onClick={() => {
              var blob = new Blob([image], { type: "image/jpeg" });
              let document = {
                from: openCamera.from,
                itemId: openCamera.itemId,
                document: blob,
                preview: image,
                name: openCamera.name + " - Picture",
              };
              let exists = documents.find(
                (element) =>
                  element.from == openCamera.from &&
                  element.itemId == openCamera.itemId
              );
              if (exists) {
                dispatch(cameraActions.deleteDocument(exists));
              }
              dispatch(cameraActions.addCapturedImage(document));
              dispatch(cameraActions.openCamera(""));
              dispatch(cameraActions.setCapturedImage(""));
              closeFullscreen();
            }}
            className={classes.fabReturnContainer}
          >
            <div className={classes.fabLike + " " + classes.fabIconHolder}>
              <i>
                <DoneIcon></DoneIcon>
              </i>
            </div>
          </div>
          <div
            onClick={() => {
              dispatch(cameraActions.setCapturedImage(""));
            }}
            className={classes.fabChangeCameraContainer}
          >
            <div className={classes.fabDislike + " " + classes.fabIconHolder}>
              <i>
                <CloseIcon></CloseIcon>
              </i>
            </div>
          </div>
        </>
      );
    }
  } else return null;
}
