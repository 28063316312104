import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import viewAccountSectionStyle from "assets/jss/xrs/views/sectionsSections/viewAccountSectionStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useContext } from "react";
import Moment from "react-moment";
import fnmoment from "moment/min/moment-with-locales";
import NumberFormat from "react-number-format";
import Context from "../../../utils/context";
import * as URLS from "../../../utils/XRSAPIs";
import BlueHeader from "./BlueHeaderSection.js";
import groupArray from "group-array";
import { useDispatch, useSelector } from "react-redux";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import { primaryColor } from "assets/jss/xrs.js";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { BorderBottom, BorderColor } from "@material-ui/icons";

const useStyles = makeStyles(viewAccountSectionStyle);
export default function ViewAccountSection(props) {
  const context = useContext(Context);
  const classes = useStyles();
  const [site, setSite] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowdata, setRowdata] = React.useState([]);
  const [grouprowdata, setGroupRowdata] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const stateSelectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );
  const selectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );
  const [account, setAccount] = React.useState([]);
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);

  const viewAccountText = useSelector(
    (state) => state.HtmlReducer.viewAccountText
  );
  const stateSite = useSelector((state) => state.SiteReducer.site);

  const createData = (Paydate, Description, Amount, Id) => {
    return { Id, Paydate, Description, Amount };
  };
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);

  React.useEffect(() => {
    fnmoment.locale(window.navigator.language.toLowerCase());

    setSite(stateSite);
    setAccount(stateSelectedAccount);

    const token = sessionStorage.getItem("access_token");
    var getdata;
    var newDate = new Date();
    getdata = JSON.stringify({
      ITEMCODE: stateSelectedAccount.itemcode,
      RECNO: stateSelectedAccount.recno,
      STARTDATE: "2020-01-01",
      ENDDATE: newDate,
    });
    fetch(URLS.GetTransactionsAPIURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: getdata,
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Transactions", data, context);
          });
          throw Error("");
        }
      })
      .then(
        (data) => {
          const dataRows = [];
          var tmptotal = 0;
          data.forEach((item, i) => {
            tmptotal += item.amount;
            dataRows.push(
              createData(
                fnmoment(item.paydate).format("L"),
                item.description,
                item.amount,
                item.transactionid
              )
            );
          });
          setRowdata(dataRows);

          if (stateSelectedAccount == "NoAccount") {
            setTotal(stateCustomer.totalbalance);
          } else {
            setTotal(stateSelectedAccount.balance);
          }

          var groupedItems = groupArray(dataRows, "Paydate");
          setGroupRowdata(groupedItems);
        },
        [page]
      )

      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Transactions", error, context);
        }
      });
  }, [stateSelectedAccount]);

  const columns = [
    { id: "Paydate", label: "Date", minWidth: 50 },
    { id: "Description", label: "Description", minWidth: 50 },
    {
      id: "Ammount",
      label: "Amount",
      minWidth: 50,
      align: "right",
      format: (value) => value.toFixed(2),
    },
  ];
  const MobileColumns = [
    {
      id: "Description",
      label: "Description",
      minWidth: 10,
      align: "left",
      paddingLeft: "0",
    },
    {
      id: "Ammount",
      label: "Amount",
      minWidth: 50,
      align: "right",
      paddingLeft: "10",
      format: (value) => value.toFixed(2),
    },
  ];

  return (
    <div>
      <BlueHeader></BlueHeader>
      <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <div> {viewAccountText}</div>

              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <BrowserView>
                    <div className={classes.tableWrapper}>
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontSize: 16,
                                  fontWeight: 600,
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowdata.map((row) => (
                            <TableRow key={row.Id}>
                              <TableCell align="left"> {row.Paydate}</TableCell>
                              <TableCell align="left">
                                {row.Description}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  color:
                                    Math.sign(row.Amount) === -1
                                      ? "red"
                                      : "blue",
                                }}
                              >
                                <NumberFormat
                                  value={row.Amount}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={currency}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell colSpan="3" align="right">
                              <NumberFormat
                                value={total}
                                displayType={"text"}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={currency}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </BrowserView>
                  <MobileView>
                    <GridContainer className={classes.tableWrapperMobile}>
                      {MobileColumns.map((column) => (
                        <GridItem
                          style={{
                            minWidth: column.minWidth,
                            fontSize: 16,
                            paddingLeft: column.paddingLeft,
                            fontWeight: 600,
                          }}
                          align={column.align}
                          xs={6}
                          sm={6}
                          md={6}
                        >
                          {column.label}
                        </GridItem>
                      ))}
                      {Object.keys(grouprowdata).map(function(date, index) {
                        return (
                          <GridContainer xs={12} sm={12} md={12}>
                            <GridItem
                              className={classes.groupRow}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              {date}
                            </GridItem>
                            {grouprowdata[date].map(function(row, index2) {
                              return (
                                <GridContainer
                                  className={classes.accountRow}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                >
                                  <GridItem
                                    className={classes.accountRow}
                                    xs={6}
                                    sm={6}
                                    md={6}
                                  >
                                    {row.Description}
                                  </GridItem>
                                  <GridItem
                                    className={classes.accountRow}
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    align="right"
                                    style={{
                                      color:
                                        Math.sign(row.Amount) === -1
                                          ? "red"
                                          : "blue",
                                    }}
                                  >
                                    <NumberFormat
                                      value={row.Amount}
                                      displayType={"text"}
                                      fixedDecimalScale={true}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      prefix={currency}
                                    />
                                  </GridItem>
                                </GridContainer>
                              );
                            })}
                          </GridContainer>
                        );
                      })}
                      <GridContainer
                        className={classes.accountRow}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <GridItem
                          className={classes.totalRow}
                          xs={12}
                          sm={12}
                          md={12}
                          align="right"
                        >
                          <NumberFormat
                            value={total}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridContainer>
                  </MobileView>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <h5 className={classes.blueTitle}>Account Number</h5>
              <p className={classes.RightText}>{stateCustomer.custnumber}</p>
              <h5 className={classes.blueTitle}>Opening Hours</h5>
              <p className={classes.RightText}>
                {selectedAccount.deptofficehours}
              </p>
              <h5 className={classes.blueTitle}>Address</h5>
              <p className={classes.RightText}>{selectedAccount.deptaddress}</p>
              <h5 className={classes.blueTitle}>Phone</h5>
              <p className={classes.RightText}>
                {" "}
                {selectedAccount.deptphonenumber}
              </p>
              <h5 className={classes.blueTitle}>Email</h5>
              <p className={classes.RightText}>{selectedAccount.deptemail}</p>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}
